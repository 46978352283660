// Dependencies
import React from 'react';
import { matchPath } from 'react-router';

import history from '../../config/history';

// This will match all routes, but we will need to add further route
//  arguments as necessary
const routesToMatch = [{ path: '/states/:state', end: false }];

/**
 * A wrapper around withRouter to support the idea of global
 * routes
 *
 * @see https://github.com/ReactTraining/react-router/issues/5870
 * @param {*} Component - Component to wrap
 * @returns {React.element} - React element
 */
export default function withGlobalRouteMatch(Component) {
  let match;
  for (let r of routesToMatch) {
    match = match ? match : matchPath(r, history.location.pathname);
  }

  let WithGlobalRouteMatch = (props) => (
    <Component {...props} globalMatch={match} />
  );
  return WithGlobalRouteMatch;
}
