/**
 * Elections for each state, specifically for ballot tracking
 * purposes.
 *
 * NOTE: This data is only used in Ballot Uploads and the Ballot
 * data reports pull information from the API.
 *
 * TODO: It might make sense to combine this config info with the
 * data that comes back from the API for the Ballots sections.
 *
 * Election fields:
 * {
 *   // This is the ID coming from the API
 *   apiId: '2020-11-03_general',
 *
 *   // The data of the election in YYYY-MM-DD format
 *   date: '2020-11-03',
 *
 *   // The type of election
 *   // 'primary' | 'general'
 *   type: 'general',
 *
 *   // Whether the election is special.  Defaults to false.
 *   special: false,
 *
 *   // Whether the election is a runoff.  Defaults to false.
 *   runoff: false,
 * }
 */

const elections = [
  {
    id: 'fl',
    elections: [
      { apiId: '2020-11-03_general', date: '2020-11-03', type: 'general' },
    ],
  },
  {
    id: 'ga',
    elections: [
      { apiId: '2020-11-03_general', date: '2020-11-03', type: 'general' },
      { apiId: '2021-01-05_special', date: '2021-01-05', type: 'special' },
      { apiId: '2021-11-02_general', date: '2021-11-02', type: 'general' },
      { apiId: '2022-05-24_primary', date: '2022-05-24', type: 'primary' },
      { apiId: '2022-11-08_general', date: '2022-11-08', type: 'general' },
      {
        apiId: '2022-12-06_general_special_runoff',
        date: '2022-12-06',
        type: 'general',
        special: true,
        runoff: true,
      },
      {
        apiId: '2022-12-20_primary_special',
        date: '2022-12-20',
        type: 'primary',
        special: true,
      },
      { apiId: '2024-03-12_primary', date: '2024-03-12', type: 'primary' },
      { apiId: '2024-05-21_primary', date: '2024-05-21', type: 'primary' },
      {
        apiId: '2024-06-18_primary_runoff',
        date: '2024-06-18',
        type: 'primary',
        runoff: true,
      },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'ia',
    elections: [
      { apiId: '2020-06-02_primary', date: '2020-06-02', type: 'primary' },
      { apiId: '2020-11-03_general', date: '2020-11-03', type: 'general' },
      { apiId: '2021-10-05_primary', date: '2021-10-05', type: 'primary' },
      { apiId: '2021-11-02_general', date: '2021-11-02', type: 'general' },
      { apiId: '2022-06-07_primary', date: '2022-06-07', type: 'primary' },
      { apiId: '2022-11-08_general', date: '2022-11-08', type: 'general' },
      { apiId: '2024-06-04_primary', date: '2024-06-04', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'mi',
    elections: [
      { apiId: '2020-08-04_primary', date: '2020-08-04', type: 'primary' },
      { apiId: '2020-11-03_general', date: '2020-11-03', type: 'general' },
      { apiId: '2021-08-03_primary', date: '2021-08-03', type: 'primary' },
      { apiId: '2021-11-02_general', date: '2021-11-02', type: 'general' },
      { apiId: '2022-08-02_primary', date: '2022-08-02', type: 'primary' },
      { apiId: '2022-11-08_general', date: '2022-11-08', type: 'general' },
      { apiId: '2024-02-27_primary', date: '2024-02-27', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'nc',
    elections: [
      { apiId: '2022-11-08_general', date: '2022-11-08', type: 'general' },
      { apiId: '2024-03-05_primary', date: '2024-03-05', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'nv',
    elections: [
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'pa',
    elections: [
      { apiId: '2020-06-02_primary', date: '2020-06-02', type: 'primary' },
      { apiId: '2020-11-03_general', date: '2020-11-03', type: 'general' },
      { apiId: '2021-11-02_general', date: '2021-11-02', type: 'general' },
      { apiId: '2022-05-17_primary', date: '2022-05-17', type: 'primary' },
      { apiId: '2022-11-08_general', date: '2022-11-08', type: 'general' },
      { apiId: '2023-01-31_special', date: '2023-01-31', type: 'special' },
      { apiId: '2023-02-07_special', date: '2023-02-07', type: 'special' },
      { apiId: '2023-05-16_primary', date: '2023-05-16', type: 'primary' },
      { apiId: '2023-09-19_special', date: '2023-09-19', type: 'special' },
      { apiId: '2023-11-07_general', date: '2023-11-07', type: 'general' },
      { apiId: '2024-02-13_special', date: '2024-02-13', type: 'special' },
      { apiId: '2024-04-23_primary', date: '2024-04-23', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'co',
    elections: [
      { apiId: '2023-11-07_general', date: '2023-11-07', type: 'general' },
      { apiId: '2024-03-05_primary', date: '2024-03-05', type: 'primary' },
      { apiId: '2024-06-25_primary', date: '2024-06-25', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'va',
    elections: [
      { apiId: '2024-03-05_primary', date: '2024-03-05', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
  {
    id: 'wi',
    elections: [
      { apiId: '2024-04-02_primary', date: '2024-04-02', type: 'primary' },
      { apiId: '2024-08-13_primary', date: '2024-08-13', type: 'primary' },
      { apiId: '2024-11-05_general', date: '2024-11-05', type: 'general' },
    ],
  },
];

export default elections;
