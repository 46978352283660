/**
 * Sentry.io config
 */

// Dependencies
import * as Sentry from '@sentry/browser';

import environment from '../modules/browser/environment';

// Whether to include sentry on develop
const sentryOnDevelopment = false;

// Errors that we filter out and track as metrics instead of issues
//  These are issues that we expect to happen occasionally and that
//  are not critical or notable unless they occur at a problematic rate
export const errorMetricReporting = {
  // Errors that signify a failed api service call
  apiErrors: ['Failed to fetch', 'API Error:'],
  // Errors that signify failed static resources
  resourceErrors: ['ChunkLoadError'],
};

/**
 * Initialize Sentry
 *
 * Configure Sentry and set values based on environment.
 *
 * @returns {object} Sentry object
 */
export default function initializeSentry() {
  const env = environment();
  const isDevelopment = env === 'local';

  // Check if this is development and we want Sentry on, or if it
  // is not development and we don't want Sentry on.
  const devCheck = !isDevelopment || (sentryOnDevelopment && isDevelopment);

  if (
    devCheck &&
    process.env.REACT_APP_SENTRY_KEY &&
    process.env.REACT_APP_SENTRY_PROJECT
  ) {
    // Config options: https://docs.sentry.io/error-reporting/configuration/?platform=javascript
    Sentry.init({
      dsn: `https://${process.env.REACT_APP_SENTRY_KEY}@sentry.io/${process.env.REACT_APP_SENTRY_PROJECT}`,
      environment: env,
      maxBreadcrumbs: 20,
      debug: isDevelopment,
      release:
        process.env.REACT_APP_SENTRY_PROJECT &&
        process.env.REACT_APP_SENTRY_VERSION
          ? `${process.env.REACT_APP_SENTRY_PROJECT}@${process.env.REACT_APP_SENTRY_VERSION}`
          : undefined,
      // By default, Sentry wraps around console statements
      // which can make it hard to see where they are coming from
      // for debugging purposes
      integrations: isDevelopment
        ? [Sentry.breadcrumbsIntegration({ console: false })]
        : undefined,
    });

    // Global context/scope
    // https://docs.sentry.io/enriching-error-data/context/?platform=browser
    //
    // We set user when user logs in
    Sentry.getCurrentScope().setTag(
      'hostname',
      isDevelopment ? 'localhost' : window.location.hostname,
    );
    if (process.env.REACT_APP_SENTRY_VERSION) {
      Sentry.getCurrentScope().setTag(
        'version',
        process.env.REACT_APP_SENTRY_VERSION,
      );
    }
    if (process.env.REACT_APP_COMMIT) {
      Sentry.getCurrentScope().setTag('commit', process.env.REACT_APP_COMMIT);
    }
  }

  return Sentry;
}
