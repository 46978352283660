/**
 * Main config.
 */

// Dependencies
import keyBy from 'lodash/keyBy';

import statesAttachLocales from '../modules/config/states-attach-locales';
import addressTypes from './address-types';
import anomalyIndexes from './anomaly-indexes';
import ballotMetricGroups from './ballot-metric-groups';
import ballotMetrics from './ballot-metrics';
import ballotScatterPresets from './ballot-scatter-presets';
import changeTypes from './change-types';
import constants from './constants';
import cookies from './cookies';
import customPopulationAutomaticCreators from './custom-pop-auto-creators';
import dates from './dates';
import demographicGroups from './demographic-groups';
import demographics from './demographics';
import electionTypes from './election-types';
import elections from './elections';
import history from './history';
import loadingFacts from './loading-facts';
import localeMatrixMetrics from './locale-matrix-metrics';
import localeMatrixMetricsNormalizations from './locale-matrix-metrics-normalizations';
import localeTypes from './locale-types';
import normalizations from './normalizations';
import prePostComparison from './pre-post-comparison';
import primaryLabels from './primary-labels';
import scales from './scales';
import initializeSentry from './sentry';
import originalStates from './states';
import statistics from './statistics';
import styles from './styles';
import synchrony from './synchrony';
import user from './user';

// Google Analytics account/property ID
const googleAnalyticsId = 'G-2JHRWENRTH';
// Debug can only be turned on in development environment
const googleAnalyticsDebug = false;

// API endpoint, allow to override with env variable, otherwise
// the legacy logic is to assume localhost, or that the API is
// hosted at the same site.
const apiEndpoint = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : process.env.NODE_ENV === 'production'
    ? '/'
    : 'http://localhost:8000/';

// Contact email
const bugReportEmail = 'support@voteshield.us';
const accountEmail = 'support@voteshield.us';
const demoEmail = 'support@voteshield.us';
const supportEmail = 'support@voteshield.us';
const pressEmail = 'press@voteshield.us';
const securityEmail = 'security@voteshield.us';

// Roles
//
// Access in the frontend does not actually stop anyone from using the API
// directly, it simply changes the UI.  Security for access needs to be managed
// in the API.
//
// These roles should reflect what is in the API.
//
// Roles that can access any state
const allStateAccessRoles = [
  'Developers',
  'VoteShieldAnalyst',
  'DataManager',
  'VoteShield',
  'ProtectDemocracy',
];
// Roles that can download raw voter data on the Analysis panel
const downloadRoles = [
  'Developers',
  'ConfirmedExternalAnalyst',
  'VoteShieldAnalyst',
];
// Roles that can upload files
const uploadRoles = ['Developers', 'DataManager'];
// Roles that can create and edit custom populations
const populationEditorRoles = ['Developers', 'VoteShieldAnalyst'];
// Roles that can view custom populations.
// Note: Population viewer is simply limited to the menu item
const populationViewerRoles = ['Developers', 'VoteShieldAnalyst'];
// Roles that can add labels.
// Permissive roles, i.e. if empty, any user can do it
const labelRoles = [];
// Roles that can alter labels that are not their own.
// Note: Not currently implemented in the API.
const labelAdminRoles = [];
// Roles that can create new labels (adds to the list of labels)
const labelCreatorRoles = ['Developers', 'VoteShieldAnalyst'];
// Roles that can edit other users' custom populations
const customPopulationEditorRoles = ['Developers', 'VoteShieldAnalyst'];
// Roles that can view the synchrony score and attribution scores
const synchronyScoreRoles = ['Developers', 'VoteShieldAnalyst'];
// Roles that can view the pre/post change comparison summary
const prePostComparisonRoles = ['Developers', 'VoteShieldAnalyst'];
// Roles that can view the year over year timeline
const yearOverYearTimelineRoles = ['Developers', 'VoteShieldAnalyst'];

// Roles that turn off analytics tracking.  In general,
// this should be used sparingly, and the analytics can
// be filtered.
const noTrackingRoles = ['NoTrackAnalytics'];

// ID separator, for instance when we combine locale type and id
const idSeparator = '-|-';

// Attach an allLocales to states
const states = statesAttachLocales(originalStates, localeTypes, idSeparator);

// Other ways to get at states
const statesByApi = keyBy(states, 'apiId');

// Export
const config = {
  accountEmail,
  addressTypes,
  allStateAccessRoles,
  anomalyIndexes,
  apiEndpoint,
  ballotMetricGroups,
  ballotMetrics,
  ballotScatterPresets,
  bugReportEmail,
  changeTypes,
  constants,
  cookies,
  dates,
  demoEmail,
  demographicGroups,
  demographics,
  downloadRoles,
  elections,
  electionTypes,
  googleAnalyticsDebug,
  googleAnalyticsId,
  history,
  idSeparator,
  initializeSentry,
  labelAdminRoles,
  labelCreatorRoles,
  labelRoles,
  loadingFacts,
  localeMatrixMetrics,
  localeMatrixMetricsNormalizations,
  localeTypes,
  normalizations,
  noTrackingRoles,
  populationEditorRoles,
  populationViewerRoles,
  prePostComparison,
  prePostComparisonRoles,
  pressEmail,
  primaryLabels,
  scales,
  securityEmail,
  states,
  statesByApi,
  statistics,
  styles,
  supportEmail,
  synchrony,
  synchronyScoreRoles,
  uploadRoles,
  user,
  yearOverYearTimelineRoles,
};

// Some helpful exports
export default config;
export {
  accountEmail,
  addressTypes,
  allStateAccessRoles,
  anomalyIndexes,
  apiEndpoint,
  ballotMetricGroups,
  ballotMetrics,
  ballotScatterPresets,
  bugReportEmail,
  changeTypes,
  constants,
  cookies,
  customPopulationAutomaticCreators,
  customPopulationEditorRoles,
  dates,
  demoEmail,
  demographicGroups,
  demographics,
  downloadRoles,
  elections,
  electionTypes,
  googleAnalyticsDebug,
  googleAnalyticsId,
  history,
  idSeparator,
  initializeSentry,
  labelAdminRoles,
  labelCreatorRoles,
  labelRoles,
  loadingFacts,
  localeMatrixMetrics,
  localeMatrixMetricsNormalizations,
  localeTypes,
  normalizations,
  noTrackingRoles,
  populationEditorRoles,
  populationViewerRoles,
  prePostComparison,
  prePostComparisonRoles,
  pressEmail,
  primaryLabels,
  scales,
  securityEmail,
  states,
  statesByApi,
  statistics,
  styles,
  supportEmail,
  synchrony,
  synchronyScoreRoles,
  uploadRoles,
  user,
  yearOverYearTimelineRoles,
};
