/**
 * Main application entry component.  This should focus on routing
 * and any top-level setup.
 */

// Global styles
import '@voteshield/styles/src/styles/index.scss';

// Dependencies
import loadable from '@loadable/component';
import React, { Component } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes } from 'react-router-dom';

import DefaultPageLayout from './components/layouts/DefaultPageLayout';
import NotFoundPage from './components/pages/errors/NotFoundPage';
import LandingPage from './components/pages/landing/LandingPage';
import LoadingPage from './components/pages/loading/LoadingPage';
import ForgotPasswordPage from './components/pages/login/ForgotPasswordPage';
import LoginPage from './components/pages/login/LoginPage';
import LogoutPage from './components/pages/logout/LogoutPage';
import {
  googleAnalyticsDebug,
  googleAnalyticsId,
  history,
  initializeSentry,
  noTrackingRoles,
  uploadRoles,
  user,
  yearOverYearTimelineRoles,
} from './config';
import {
  gaLogin,
  gaPageView,
  gaSetUserId,
  gaUserProperties,
  initializeGoogleAnalytics,
} from './modules/ga/google-analytics';
import AuthorizedAuthenticatedAny from './providers/auth/AuthorizedAuthenticatedAny';
import AuthorizedAuthenticatedWithRole from './providers/auth/AuthorizedAuthenticatedWithRole';
import AuthorizedRequiresPasswordChange from './providers/auth/AuthorizedRequiresPasswordChange';
import AuthorizedState from './providers/auth/AuthorizedState';
import AuthorizedUnauthenticated from './providers/auth/AuthorizedUnauthenticated';
import ErrorBoundary from './providers/errors/ErrorBoundary';
import HistoryRouter from './providers/routes/HistoryRouter';
import PreferredStateRedirect from './providers/routes/PreferredStateRedirect';
import ScrollToTop from './providers/routes/ScrollToTop';

// Code split pages.
// TODO: Use a data structure to make these instead of repeating the same code.
const AboutPage = loadable(() => import('./components/pages/about/AboutPage'), {
  fallback: <LoadingPage />,
});
const TermsOfUsePage = loadable(
  () => import('./components/pages/legal/TermsOfUsePage'),
  { fallback: <LoadingPage /> },
);
const PrivacyPolicyPage = loadable(
  () => import('./components/pages/legal/PrivacyPolicyPage'),
  { fallback: <LoadingPage /> },
);
const PasswordPage = loadable(
  () => import('./components/pages/account/PasswordPage'),
  { fallback: <LoadingPage /> },
);
const SecurityPage = loadable(
  () => import('./components/pages/account/SecurityPage'),
  { fallback: <LoadingPage /> },
);
const ProfilePage = loadable(
  () => import('./components/pages/account/ProfilePage'),
  { fallback: <LoadingPage /> },
);
const OnboardingNewUserPage = loadable(
  () => import('./components/pages/onboarding/NewUser'),
  { fallback: <LoadingPage /> },
);
const OnboardingNewUserAccountPage = loadable(
  () => import('./components/pages/onboarding/NewUserAccount'),
  { fallback: <LoadingPage /> },
);
const OnboardingNewUserPreferencePage = loadable(
  () => import('./components/pages/onboarding/NewUserPreference'),
  { fallback: <LoadingPage /> },
);
const OnboardingNewUserLegalPage = loadable(
  () => import('./components/pages/onboarding/NewUserLegal'),
  { fallback: <LoadingPage /> },
);
const ReturningUserPage = loadable(
  () => import('./components/pages/onboarding/ReturningUser'),
  { fallback: <LoadingPage /> },
);
const ReturningUserPreferencePage = loadable(
  () => import('./components/pages/onboarding/ReturningUserPreference'),
  { fallback: <LoadingPage /> },
);
const ReturningUserLegalPage = loadable(
  () => import('./components/pages/onboarding/ReturningUserLegal'),
  { fallback: <LoadingPage /> },
);
const ReturningUserLegalOnlyPage = loadable(
  () => import('./components/pages/onboarding/ReturningUserLegalOnly'),
  { fallback: <LoadingPage /> },
);
const BallotsPage = loadable(
  () => import('./components/pages/ballots/BallotsPage'),
  { fallback: <LoadingPage /> },
);
const BallotsReportPage = loadable(
  () => import('./components/pages/ballots/BallotsReportPage'),
  { fallback: <LoadingPage /> },
);
const BallotsElectionPage = loadable(
  () => import('./components/pages/ballots/BallotsElectionPage'),
  { fallback: <LoadingPage /> },
);
const TimelinePage = loadable(
  () => import('./components/pages/timeline/TimelinePage'),
  { fallback: <LoadingPage /> },
);
const YearOverYearPage = loadable(
  () => import('./components/pages/timeline/YearOverYearPage'),
  { fallback: <LoadingPage /> },
);
const AnomaliesPage = loadable(
  () => import('./components/pages/anomalies/AnomaliesPage'),
  { fallback: <LoadingPage /> },
);
const LocaleMatrixPage = loadable(
  () => import('./components/pages/locale-matrix/LocaleMatrixPage'),
  { fallback: <LoadingPage /> },
);
const CustomPopulationsPage = loadable(
  () => import('./components/pages/custom-populations/CustomPopulationsPage'),
  { fallback: <LoadingPage /> },
);
const CustomPopulationAddPage = loadable(
  () => import('./components/pages/custom-populations/CustomPopulationAddPage'),
  { fallback: <LoadingPage /> },
);
const CustomPopulationEditPage = loadable(
  () =>
    import('./components/pages/custom-populations/CustomPopulationEditPage'),
  { fallback: <LoadingPage /> },
);
const UploadListPage = loadable(
  () => import('./components/pages/upload/UploadListPage'),
  { fallback: <LoadingPage /> },
);
const NewVoterUploadPage = loadable(
  () => import('./components/pages/upload/NewVoterUploadPage'),
  { fallback: <LoadingPage /> },
);
const NewBallotUploadPage = loadable(
  () => import('./components/pages/upload/NewBallotUploadPage'),
  { fallback: <LoadingPage /> },
);

// Initialize sentry error watching
const Sentry = initializeSentry();

// Initialize google analytics
initializeGoogleAnalytics(googleAnalyticsId, {
  testMode: process.env.NODE_ENV === 'test' || process.env.CI,
  debugMode: googleAnalyticsDebug && process.env.NODE_ENV !== 'production',
});
gaPageView(`${window.location.pathname}${window.location.search}`);

class App extends Component {
  constructor(props) {
    super(props);

    // Already logged in user
    if (user.isAuthenticated()) {
      this.userTracking();
    }

    // Handle user events, like login
    user.on('login', ({ options }) => {
      this.userTracking();
      gaLogin();

      // On boarding for users that don't have their
      // profiles filled out
      if (!user.hasProfileFilled()) {
        history.push('/onboarding/returning-user');
      }
      else if (!user.hasLegalAgreedTo()) {
        history.push('/onboarding/returning-user/legal-only');
      }
      else {
        history.push(options && options.redirect ? options.redirect : '/');
      }
    });

    // Password change
    user.on('requires-password-change', () => {
      history.push('/onboarding/new-user');
    });

    // Logout
    user.on('logout', () => {
      history.push('/');
      window.location.reload();
    });
    user.on('logout-initiated', () => {
      history.push('/logout');
    });

    // Listen to page
    this._historyUnlistener = history.listen(({ location, action }) => {
      if (!action || !action.match(/replace/i)) {
        gaPageView(`${location.pathname}${location.search}`);
      }
    });
  }

  // User tracking configure
  userTracking() {
    if (user.isAuthenticated()) {
      // Turn off GA for specific roles
      if (noTrackingRoles && user.hasAnyRole(noTrackingRoles)) {
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
        // Unsure if this works in GA4, though some info suggests it should.
        window[`ga-disable-${googleAnalyticsId}`] = true;
      }

      // Tell GA about user
      gaSetUserId(user.id);

      // Custom user properties
      gaUserProperties({
        preferred_state: user?.profile?.state || 'no-preferred-state',
        organization: user?.profile?.organization || 'no-organization-set',
        email_domain: user?.profile?.email
          ? user.profile.email.split('@')[1]
          : 'no-email-set',
        access_groups: user?.roles || [],
      });

      // Tell Sentry about user
      Sentry.getCurrentScope().setUser({
        id: user.id,
        username: user.username,
      });
    }
  }

  // Unmount
  componentWillUnmount() {
    if (this._historyUnlistener) {
      this._historyUnlistener();
    }
  }

  render() {
    return (
      <HelmetProvider>
        <ErrorBoundary>
          <HistoryRouter history={history}>
            <ScrollToTop />

            <DefaultPageLayout>
              <Routes>
                <Route path="/">
                  {/* Landing */}
                  <Route index element={<LandingPage />} />

                  {/* About */}
                  <Route path="about" element={<AboutPage />} />

                  {/* Legal */}
                  <Route path="legal">
                    <Route
                      index
                      element={<Navigate to="terms-of-use" replace={true} />}
                    />
                    <Route path="terms-of-use" element={<TermsOfUsePage />} />
                    <Route
                      path="privacy-policy"
                      element={<PrivacyPolicyPage />}
                    />
                  </Route>

                  {/* Login/logout */}
                  <Route
                    path="logout"
                    element={
                      <AuthorizedAuthenticatedAny redirect="/login">
                        <LogoutPage />
                      </AuthorizedAuthenticatedAny>
                    }
                  />
                  <Route
                    path="login"
                    element={
                      <AuthorizedUnauthenticated redirect="/">
                        <LoginPage />
                      </AuthorizedUnauthenticated>
                    }
                  />

                  {/* Forgot password */}
                  <Route
                    path="forgot"
                    element={
                      <AuthorizedUnauthenticated redirect="/">
                        <ForgotPasswordPage />
                      </AuthorizedUnauthenticated>
                    }
                  />

                  {/* Account */}
                  <Route path="account">
                    <Route
                      index
                      element={<Navigate to="profile" replace={true} />}
                    />
                    <Route
                      path="profile"
                      element={
                        <AuthorizedAuthenticatedAny redirect="/login">
                          <ProfilePage />
                        </AuthorizedAuthenticatedAny>
                      }
                    />
                    <Route
                      path="password"
                      element={
                        <AuthorizedAuthenticatedAny redirect="/login">
                          <PasswordPage />
                        </AuthorizedAuthenticatedAny>
                      }
                    />
                    <Route
                      path="security"
                      element={
                        <AuthorizedAuthenticatedAny redirect="/login">
                          <SecurityPage />
                        </AuthorizedAuthenticatedAny>
                      }
                    />
                  </Route>

                  {/* States */}
                  <Route path="states">
                    <Route index element={<NotFoundPage />} />

                    <Route path=":state">
                      <Route index element={<NotFoundPage />} />

                      {/* Ballots */}
                      <Route path="ballots">
                        <Route
                          index
                          element={
                            <AuthorizedState>
                              <BallotsPage />
                            </AuthorizedState>
                          }
                        />
                        <Route
                          path="report"
                          element={
                            <AuthorizedState>
                              <BallotsReportPage />
                            </AuthorizedState>
                          }
                        />
                        <Route
                          path="election"
                          element={
                            <AuthorizedState>
                              <BallotsElectionPage />
                            </AuthorizedState>
                          }
                        />
                      </Route>

                      {/* Main visualizations */}
                      <Route path="timeline">
                        <Route
                          index
                          element={
                            <AuthorizedState>
                              <TimelinePage />
                            </AuthorizedState>
                          }
                        />
                        <Route
                          path="year-over-year"
                          element={
                            <AuthorizedAuthenticatedWithRole
                              roles={yearOverYearTimelineRoles}
                            >
                              <YearOverYearPage />
                            </AuthorizedAuthenticatedWithRole>
                          }
                        />
                      </Route>
                      <Route
                        path="anomalies"
                        element={
                          <AuthorizedState>
                            <AnomaliesPage />
                          </AuthorizedState>
                        }
                      />
                      <Route
                        path="locales"
                        element={
                          <AuthorizedState>
                            <LocaleMatrixPage />
                          </AuthorizedState>
                        }
                      />

                      {/* Custom populations */}
                      <Route path="populations">
                        <Route
                          index
                          element={
                            <AuthorizedState>
                              <CustomPopulationsPage />
                            </AuthorizedState>
                          }
                        />
                        <Route
                          path="add"
                          element={
                            <AuthorizedState>
                              <CustomPopulationAddPage />
                            </AuthorizedState>
                          }
                        />
                        <Route
                          path="edit"
                          element={
                            <AuthorizedState>
                              <CustomPopulationEditPage />
                            </AuthorizedState>
                          }
                        />
                      </Route>
                    </Route>
                  </Route>

                  {/* Direct visualization views */}
                  <Route path="views">
                    <Route index element={<NotFoundPage />} />
                    <Route
                      path="ballots"
                      element={<PreferredStateRedirect view="ballots" />}
                    />
                    <Route
                      path="elections"
                      element={<PreferredStateRedirect view="elections" />}
                    />
                    <Route
                      path="timeline"
                      element={<PreferredStateRedirect view="timeline" />}
                    />
                    <Route
                      path="anomalies"
                      element={<PreferredStateRedirect view="anomalies" />}
                    />
                    <Route
                      path="locales"
                      element={<PreferredStateRedirect view="locales" />}
                    />
                    <Route
                      path="populations"
                      element={<PreferredStateRedirect view="populations" />}
                    />
                  </Route>

                  {/* Upload */}
                  <Route path="upload">
                    <Route
                      index
                      element={<Navigate to="list" replace={true} />}
                    />
                    <Route
                      path="list"
                      element={
                        <AuthorizedAuthenticatedWithRole roles={uploadRoles}>
                          <UploadListPage />
                        </AuthorizedAuthenticatedWithRole>
                      }
                    />
                    <Route
                      path="voter"
                      element={
                        <AuthorizedAuthenticatedWithRole roles={uploadRoles}>
                          <NewVoterUploadPage />
                        </AuthorizedAuthenticatedWithRole>
                      }
                    />
                    <Route
                      path="ballot"
                      element={
                        <AuthorizedAuthenticatedWithRole roles={uploadRoles}>
                          <NewBallotUploadPage />
                        </AuthorizedAuthenticatedWithRole>
                      }
                    />
                  </Route>

                  {/* Onboarding */}
                  <Route path="onboarding">
                    <Route index element={<NotFoundPage />} />

                    {/* New user */}
                    <Route path="new-user">
                      <Route
                        index
                        element={
                          <AuthorizedRequiresPasswordChange>
                            <OnboardingNewUserPage
                              onSaveSuccess={() =>
                                history.push('/onboarding/new-user/account')
                              }
                            />
                          </AuthorizedRequiresPasswordChange>
                        }
                      />

                      <Route
                        path="account"
                        element={
                          <AuthorizedAuthenticatedAny>
                            <OnboardingNewUserAccountPage
                              onSaveSuccess={() =>
                                history.push('/onboarding/new-user/preferences')
                              }
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />

                      <Route
                        path="preferences"
                        element={
                          <AuthorizedAuthenticatedAny>
                            <OnboardingNewUserPreferencePage
                              onSaveSuccess={() =>
                                history.push('/onboarding/new-user/legal')
                              }
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />

                      <Route
                        path="legal"
                        element={
                          <AuthorizedAuthenticatedAny>
                            <OnboardingNewUserLegalPage
                              onSaveSuccess={() => {
                                history.push('/');
                                // HACK: We need the menu items to re-render to recognize the
                                // new user authorization.  This is probably not the best way
                                // to do this, but it works and helps reset everything.
                                window.location.reload();
                              }}
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />
                    </Route>

                    {/* Returning user.  In general, this case is rare and used for someone who doesn't have a state preference */}
                    <Route path="returning-user">
                      <Route
                        index
                        element={
                          <AuthorizedAuthenticatedAny>
                            <ReturningUserPage
                              onSaveSuccess={() =>
                                history.push(
                                  '/onboarding/returning-user/preferences',
                                )
                              }
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />

                      <Route
                        path="preferences"
                        element={
                          <AuthorizedAuthenticatedAny>
                            <ReturningUserPreferencePage
                              onSaveSuccess={() =>
                                history.push('/onboarding/returning-user/legal')
                              }
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />

                      <Route
                        path="legal"
                        element={
                          <AuthorizedAuthenticatedAny>
                            <ReturningUserLegalPage
                              onSaveSuccess={() => {
                                history.push('/');
                                window.location.reload();
                              }}
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />

                      {/* Returning user who only needs to agree to legal */}
                      <Route
                        path="legal-only"
                        element={
                          <AuthorizedAuthenticatedAny>
                            <ReturningUserLegalOnlyPage
                              onSaveSuccess={() => {
                                history.push('/');
                                window.location.reload();
                              }}
                            />
                          </AuthorizedAuthenticatedAny>
                        }
                      />
                    </Route>
                  </Route>

                  {/* Not found */}
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Routes>
            </DefaultPageLayout>
          </HistoryRouter>
        </ErrorBoundary>
      </HelmetProvider>
    );
  }
}

export default App;
